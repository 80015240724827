<template>
  <div class="powered-by-clebex">
    <span class="text">Powered by</span>
    <img src="../../assets/images/main-nav-footer-logo.png" alt="Clebex logo" />
  </div>
</template>

<script>
export default {
  name: "PoweredByClebex"
};
</script>
